import { Action } from "../../../../store/action.type"

const initialState = {
    userList: [],
    total: 0,
    userCurrentPage : undefined
}

const Users = (state = initialState, {type, payload}: Action) => {
    switch(type) {
        case 'getUsers': return {...state, userList: payload.data.admins, total: payload.data.total}
        case 'changeUserCurrentPage': return {...state, userCurrentPage: +payload}
        case 'emptyList': return {...state, userList: []}
        default: return state;
    }
}

export default Users;