import { Button, Dropdown } from "react-bootstrap";
import { SmogwiseLogo } from "../../assets/icon-component";
import "./header.scss";
import CustomModal from "../CustomModal";
import { useState } from "react";

const Header = () => {
  const [showModal, setShowModal] = useState(false);
  const logoutUser = () => {
    localStorage.clear();
        window.location.pathname = "/";
  }

  const logoutModalBody = () => {
    return (
      <div>
        <h6 className="mb-3">Are you sure you want to logout?</h6>
        <div className="text-end mt-4">
          <Button
            variant="secondary"
            type="button"
            className="mx-3"
            onClick={() => setShowModal(false)}
          >
            No
          </Button>
          <Button variant="primary" type="button" onClick={logoutUser}>
            Yes
          </Button>
        </div>
      </div>
    );
  };

  

  const logout = () => {
    setShowModal(true);
  };

  return (
    <>
    <header className="d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center">
        <SmogwiseLogo height="60" width="60" />
        <h3 className="mx-3 mb-0">Smogwise</h3>
      </div>
      <Dropdown>
        <Dropdown.Toggle variant="secondary" className="border-0" id="dropdown-basic">
          Admin
        </Dropdown.Toggle>

        <Dropdown.Menu variant="dark">
          <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </header>
    <CustomModal
        show={showModal}
        heading="Logout"
        body={logoutModalBody()}
        handleClose={() => setShowModal(false)}
      />
    </>
  );
};

export default Header;
