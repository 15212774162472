import { combineReducers } from "redux";
import Test from '../modules/pages/Test Data/store/reducer';
import Spinner from '../components/CustomSpinner/store/reducer';
import Users from "../modules/pages/User management/store/reducer";
import Devices from "../modules/pages/Devices/store/reducer";

const rootReducer = combineReducers({
    Test,
    Spinner,
    Users,
    Devices
})

export default rootReducer;