import { Action } from "../../../../store/action.type"

const initialState = {
    deviceList: [],
    total: 0,
    deviceCurrentPage : undefined,
    searchObj: {},
}

const Devices = (state = initialState, {type, payload}: Action) => {
    switch(type) {
        case 'getDevices': return {...state, deviceList: payload.data.obd, total: payload.data.total}
        case 'changeDeviceCurrentPage': return {...state, deviceCurrentPage: +payload}
        case 'emptyList': return {...state, deviceList: []}
        case 'setSearchObject': return {...state, searchObj: payload}
        case 'clearSearchObject': return {...state, searchObj: {}, testCurrentPage: 1}
        default: return state;
    }
}

export default Devices;