import React from "react";
import { Route } from './route.type';

const Auth = React.lazy(() => import('../modules/Auth'));
const Login = React.lazy(() => import('../modules/Auth/Login'));
const ForgetPassword = React.lazy(() => import('../modules/Auth/ForgetPassword'));
const ResetPassword = React.lazy(() => import('../modules/Auth/ResetPassword'));


const auth: Route = {
    path: '/auth',
    exact: true,
    isProtected: false,
    component: Auth
}
const login: Route = {
    path: 'login',
    exact: true,
    isProtected: false,
    component: Login
}

const forgetPassword: Route = {
    path: 'forget-password',
    exact: true,
    isProtected: false,
    component: ForgetPassword
}

const resetPassword: Route = {
    path: 'reset-password',
    exact: true,
    isProtected: false,
    component: ResetPassword
}

export const publicRouteList = [
    login,
    forgetPassword,
    resetPassword
]