import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import {createLogger} from 'redux-logger';
import rootReducer from './rootReducer';
import ThunkMiddleware from 'redux-thunk';

const loggerMiddleWare = createLogger();

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(ThunkMiddleware, loggerMiddleWare))
)

export default store;