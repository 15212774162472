import { Action } from "../../../../store/action.type"

export const initialState = {
    deviceList: [],
    deviceCSVList: [],
    vehicleData: {},
    total: 0,
    searchObj: {},
    testCurrentPage: undefined
}

const Test = (state = initialState, {type, payload}: Action) => {
    switch(type) {
        case 'getDevices': return {...state, deviceList: payload.data.data, total: payload.data.total}
        case 'getDevicesData': return {...state, deviceCSVList: payload.data.data}
        case 'getVehicleData': return {...state, vehicleData: payload} 
        case 'setSearchObject': return {...state, searchObj: payload}
        case 'clearSearchObject': return {...state, searchObj: {}, testCurrentPage: 1}
        case 'changeTestCurrentPage': return {...state, testCurrentPage: +payload}
        case 'emptyList': return {...state, deviceList: []}
        case 'emptyCSVList': return {...state,  deviceCSVList: []}
        case 'emptyDetailsList': return {...state, vehicleData: {}}
        default: return state
    }
}

export default Test;