import { Action } from "../../../store/action.type"

const initialState = {
    isLoading: false
}

const Spinner = (state = initialState, {type}: Action) => {
    switch(type) {
        case 'startSpinner': return {...state, isLoading: true}
        case 'stopSpinner': return {...state, isLoading: false}
        default: return state;
    }
}


export default Spinner;