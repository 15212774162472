import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Auth from "./modules/Auth";
import Pages from "./modules/pages";
import { publicRouteList } from "./navigation/publicRoute";
import { privateRouteList } from "./navigation/privateRoute";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import "./App.css";
import { Spinner } from "react-bootstrap";
import UserManagement from "./modules/pages/User management";

const spinner = (
  <div
    style={{
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    }}
  >
    <Spinner animation="border" variant="primary" />
  </div>
);

toast.configure();
const App = () => {
  const token = localStorage.getItem("smogwise-admin-token");
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate replace to="/auth" />}></Route>
          <Route path="/auth" element={<Auth />}>
            <Route path="/auth" element={<Navigate replace to="login" />} />
            {publicRouteList.map((route, rest) => (
              <Route
                key={route.path}
                path={route.path}
                element={
                  !token ? (
                    <React.Suspense fallback={spinner}>
                      <route.component />
                    </React.Suspense>
                  ) : (
                    <Navigate replace to="/pages" />
                  )
                }
                {...rest}
              />
            ))}
          </Route>
          <Route path="/pages" element={<Pages />}>
            {/* <Route path="/pages" element={<Navigate replace to="devices" />} /> */}
            {
              localStorage.getItem('smogwise-admin-role')!='super-admin' ? 
              <Route path="/pages" element={<Navigate replace to="devices" />} /> 
              :
              <Route path="/pages" element={<Navigate replace to="user-management" />}/>
            }
            {privateRouteList.map((route, rest) => (
              <Route
                key={route.path}
                path={route.path}
                element={
                  token ? (
                    <React.Suspense fallback={spinner}>
                      <route.component />
                    </React.Suspense>
                  ) : (
                    <Navigate replace to="/auth" />
                  )
                }
                {...rest}
              />
            ))}
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
