import { Outlet } from "react-router";
import Header from "../../components/Header";
import SideMenu from "../../components/Sidemenu";
import './index.scss'

const Pages = () => {
  return (
    <div>
      <Header />
      <div className="d-flex">
        <div style={{ width: "270px", minHeight: "calc(100vh - 76px)" }}>
          <SideMenu />
        </div>
        <div style={{ width: "calc(100% - 270px)", background: '#f5f6fa', }}> 
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Pages;
