import { NavLink } from "react-router-dom";
import "./sidemenu.scss";

const SideMenu = () => {

  let role = localStorage.getItem('smogwise-admin-role')



  return (
    <div className="sidemenu">
      <ul>
        {role==='super-admin' &&
         <li>
         <NavLink
           to="user-management?page=1"
           className={({ isActive }) => (isActive ? "active" : "")}
         ><i className="fas fa-users px-3"></i>
           User Management
         </NavLink>
       </li> 
        }
        <li>
          <NavLink
            to="devices?page=1"
            className={({ isActive }) => (isActive ? "active" : "")}
          ><i className="fas fa-address-card px-3"></i>
            Devices
          </NavLink>
        </li>
       
        <li>
          <NavLink
            to="test-data?page=1"
            className={({ isActive }) => (isActive ? "active" : "")}
          ><i className="fas fa-database px-3"></i>
            Test Data
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default SideMenu;
