import React from 'react';
import { Route } from './route.type';

const Pages = React.lazy(() => import('../modules/pages'));
const TestData = React.lazy(() => import('../modules/pages/Test Data'));
const UserManagement = React.lazy(() => import('../modules/pages/User management'));
const TestDetails = React.lazy(() => import('../modules/pages/Test Data/TestDetails'));
const Devices = React.lazy(() => import('../modules/pages/Devices'));

const pages: Route = {
    path: '/pages',
    exact: true,
    isProtected: true,
    component: Pages
}

const devices: Route = {
    path: 'devices',
    exact: true,
    isProtected: true,
    component: Devices
}

const search: Route = {
    path: 'user-management',
    exact: true,
    isProtected: true,
    component: UserManagement
}

const testDetails: Route = {
    path: 'test-data/test-details',
    exact: true,
    isProtected: true,
    component: TestDetails
}

const testData: Route = {
    path: 'test-data',
    exact: true,
    isProtected: true,
    component: TestData

}

export const privateRouteList = [
    devices,
    search,
    testDetails,
    testData
]